<script setup>
  import {
    onMounted,
    ref,
  } from 'vue'

  import { useAjax } from '@composables/useAjax.js'
  import { generateUrl, shuffleArray } from '@shared/util.js'

  defineProps({
    suffix: {
      default: '',
      type: String
    }
  })

  const large = ref(null)
  const small = ref(null)

  const { callGet } = useAjax()

  onMounted(async () => {
    const url = generateUrl('banners/side')
    url.searchParams.append("u", window.app.common.cacheKey.banner)
    const response = await callGet(url.toString())

    if (response.banners) {
      if (response.banners.large) {
        large.value = shuffleArray(response.banners.large)
        small.value = shuffleArray(response.banners.small)
      }
    }
  })
</script>

<template>
  <div v-if="large" class="bnr_area parts_side">
    <div v-for="({ url, image }, index) in large" :key="`large-banner-${index}`" :id="`lbnr${index + 1}${suffix}`" class="bnr">
      <a target="_blank" :href="url">
        <img :src="image">
      </a>
    </div>
  </div>
  <div v-if="small" class="bnr_area parts_side">
    <div v-for="({ url, image }, index) in small" :key="`small-banner-${index}`" :id="`sbnr${index + 1}${suffix}`" class="bnr">
      <a target="_blank" :href="url">
        <img :src="image">
      </a>
    </div>
  </div>
</template>
